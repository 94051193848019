@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400;500;600;700&display=swap');
*, *::before, *::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  --green: rgb(139, 168, 124);
  --dark-green: rgb(94, 101, 89);
  --bg-black-50: rgba(0, 0, 0, 0.4);
  --green-whatsapp: rgb(48, 160, 92);
  --dark-green-whatsapp: rgb(34, 112, 64);
  --light-gray: rgb(179, 197, 170);
  --light-cream: rgb(249, 246, 231);
  --dark-cream-solid: rgb(125, 129, 117);
  --gray: rgb(178, 178, 178);
  --gray-light: rgb(217, 217, 217);
  --gray-lightest: rgb(220, 229, 215);
  --dark-cream: rgba(94, 101, 89, 0.79);
}

body {
  font-family: 'Assistant', sans-serif;
}

html {
  font-size: 16px;
}

hr {
  width: 25%;
  height: 10px;
  margin: auto;
  background-color: var(--dark-green);
}

.hr-white {
  width: 25%;
  height: 10px;
  margin: auto;
  background-color: white;
}

/* '.container, .wrapper' sets viewport max-width */

.wrapper {
  width: 100%;
  margin: auto;
  max-width: 1324px;
}

.container-fluid {
  width: 100%;
  max-width: 95%;
  margin: 0 auto;
}

.container {
  width: 100%;
  max-width: 82%;
  margin: 0 auto;
}

/* '#hero-section' add background-image */

#hero-section {
  background: url(../img/bg-hero.jpg) no-repeat;
  background-size: 100% 100%;
  padding: 20px 0;
}

#whats-going {
  background: url(../img/bg-hero.jpg) no-repeat fixed;
  background-size: 100% 100%;
  padding: 20px 0;
}

/* '.hero-text' style text-content */

.hero-text {
  padding-top: 20px;
  text-align: center;
  color: white;
}

.hero-text p {
  background-color: var(--green);
  font-size: 1.3rem;
  font-weight: 300;
  text-shadow: 1px 1px 2px var(--bg-black-50);
}

.hero-text h1 {
  background-color: var(--bg-black-50);
  padding: 0 20px;
  font-size: 1.9rem;
  font-weight: 700;
  padding: 3px 0;
}

.hero-text h1:nth-child(3) {
  margin: 22px 0 0 0;
  background-color: var(--green);
  padding: 5px 0;
  text-shadow: 1px 1px 2px var(--bg-black-50);
}

/* '#video-container' adjust video position */

#video-container {
  text-align: center;
  margin: 50px 0 0 0;
}

#video-container p {
  font-size: 1.5rem;
}

#video-container p span.underline {
  font-weight: bold;
  text-decoration: underline;
}

#video-container .video-footage {
  width: 80%;
  height: 100%;
}

/* Button lg */

.button-lg {
  width: 100%;
  background-color: var(--green);
  padding: 20px 0;
  text-align: center;
  color: white;
  font-style: italic;
  margin-top: 20px;
  border-radius: 5px;
  box-shadow: 0 6px 0 rgb(97, 117, 87);
  cursor: pointer;
  transition: all .3s ease-in-out;
}

.button-lg:hover {
  box-shadow: 0 0 0 rgb(97, 117, 87);
  filter: brightness(1.1);
  transform: scale(1.04);
}

.button-lg p:nth-child(1) {
  font-size: 3rem;
  font-weight: bold;
}

.button-lg p:nth-child(2) {
  font-size: 1.8rem;
  font-weight: bold;
  color: var(--light-gray);
}

/* Button lg */

.button-slim {
  width: 100%;
  background-color: var(--green);
  padding: 10px 0;
  text-align: center;
  color: white;
  margin-top: 20px;
  font-style: italic;
  border-radius: 5px;
  box-shadow: 0 6px 0 rgb(97, 117, 87);
  border: 1px solid var(--dark-green);
  cursor: pointer;
  transition: all .3s ease-in-out;
}

.button-slim:hover {
  box-shadow: 0 0 0 rgb(97, 117, 87);
  filter: brightness(1.1);
  transform: scale(1.04);
}

.button-slim p:nth-child(1) {
  font-size: 2rem;
  font-weight: 700;
  text-shadow: 1px 1px 2px var(--bg-black-50);
}

.button-slim p:nth-child(2) {
  font-size: 1.4rem;
  font-weight: bold;
  color: var(--gray-lightest);
  font-style: italic;
  font-weight: 400;
}


/* button sm */

.button-sm {
  display: block;
  margin: 20px auto;
  width: 280px;
  font-style: italic;
  background-color: var(--green-whatsapp);
  padding: 10px 0;
  text-align: center;
  color: white;
  border-radius: 5px;
  box-shadow: 0 6px 0 var(--dark-green-whatsapp);
  cursor: pointer;
  transition: all .3s ease-in-out;
}

.button-sm:hover {
  box-shadow: 0 0 0;
  filter: brightness(1.1);
  transform: scale(1.04);
}

.button-sm p:nth-child(1) {
  font-size: .9rem;
  font-weight: bold;
}

.button-sm p:nth-child(2) {
  font-size: .8rem;
  font-weight: bold;
  color: var(--light-gray);
}

/* Button md */

.button-md {
  width: 500px;
  margin: auto;
  background-color: var(--green);
  padding: 20px 0;
  font-style: italic;
  text-align: center;
  color: white;
  margin-top: 20px;
  border-radius: 5px;
  box-shadow: 0 6px 0 rgb(97, 117, 87);
  cursor: pointer;
  transition: all .3s ease-in-out;
}

.button-md:hover {
  box-shadow: 0 0 0 rgb(97, 117, 87);
  filter: brightness(1.1);
  transform: scale(1.04);
}

.button-md p:nth-child(1) {
  font-size: 2rem;
  font-weight: bold;
}

.button-md p:nth-child(2) {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--light-gray);
}

/* Button md white */

.button-md-white {
  width: 450px;
  margin: 20px auto;
  font-style: italic;
  background-color: white;
  padding: 20px 0;
  text-align: center;
  color: var(--dark-green);
  border-radius: 5px;
  box-shadow: 0 6px 0 var(--gray);
  cursor: pointer;
  transition: all .3s ease-in-out;
}

.button-md-white:hover {
  box-shadow: 0 0 0;
  transform: scale(1.04);
}

.button-md-white p:nth-child(1) {
  font-size: 2rem;
  font-weight: bold;
}

.button-md-white p:nth-child(2) {
  font-size: 1.5rem;
  font-weight: 400;
}

/* Section 2 */

.following-figure {
  margin: 40px 0;
  text-align: center;
}

.following-figure h2 {
  color: var(--dark-green);
  font-size: 4rem;
  text-shadow: 1px 1px 2px var(--bg-black-50);
  padding: 0 0 20px 0;
}

.figure-image img {
  margin-top: 30px;
  width: 100%;
  height: auto;
}

.facts {
  margin-top: 80px;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 4fr;
  text-align: start;
  margin: 50px 0;
}

.grid-reverse {
  display: grid;
  grid-template-columns: 4fr 1fr;
  text-align: start;
}

.grid h2, .grid-reverse h2 {
  font-size: 2.5rem;
  text-decoration: underline;
}

.grid p, .grid-reverse p {
  font-size: 1.5rem;
  font-weight: 500;
}

.grid p span {
  text-decoration: underline;
  font-weight: 700;
}

.grid-reverse p span {
  font-weight: 700;
}

/* Excess weight */

#excess-weight {
  background-color: var(--dark-green);
  color: white;
  text-align: center !important;
  padding: 30px 0;
}

#excess-weight h2 {
  font-size: 3.7rem;
  text-shadow: 1px 1px 2px var(--bg-black-50);
}

#excess-weight h3 {
  font-size: 2rem;
  text-shadow: 1px 1px 2px var(--bg-black-50);
  text-align: center !important;
}

/* Re-method */

#re-method {
  margin: 40px auto;
  width: 80%;
}

.re-method-heading {
  text-align: center;
}

.re-method-heading h2, h4 {
  color: var(--dark-green);
  text-shadow: 1px 1px 2px var(--bg-black-50);
}

.re-method-heading h2 {
  font-size: 3.3rem;
}

.re-method-heading h4 {
  font-size: 1.8rem;
  margin-bottom: 20px;
}

.re-method img {
  width: 250px;
  border: 2px solid var(--dark-green);
}

.re-method {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.re-method p {
  font-size: 1.5rem;
  margin: 0 50px 20px 0;
  width: 400px;
  text-align: right;
}

.re-method p span {
  color: var(--dark-green);
  font-weight: 700;
}

/* Small part */

.small-part {
  background-color: var(--dark-green);
  color: white;
  text-align: center;
  height: 230px;
  display: flex;
  flex-direction: column;
}

.small-part h2 {
  font-size: 3.7rem;
  text-shadow: 1px 1px 2px var(--bg-black-50);
  margin-top: 30px;
}

.small-part h3 {
  font-size: 2rem;
  text-shadow: 1px 1px 2px var(--bg-black-50);
  text-align: center;
}

/* tried diets */

.card {
  width: 90%;
  background-color: var(--light-cream);
  margin: 80px auto;
  border-radius: 0 0 20px 20px;
}

.card .card-section-1 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 50px auto;
  padding: 20px;
}

.card .card-section-1 .content-section-1 {
  margin: 0 20px;
}

.card .card-section-1 .whatsapp-screenshot {
  width: 100%;
  height: 50%;
  margin: 20px 0 0 0;
}

.card .card-section-1 .whatsapp-screenshot img {
  width: 60%;
  height: 100%;
  border: 4px solid var(--dark-green);
  margin: auto !important;
  padding: 1px;
  box-shadow: 0 2px 5px 2px rgba(0, 0, 0,.3);
}

.card .card-section-1 .content-section-1 h3 {
  font-size: 1.6rem;
  font-weight: 500;
  color: #000;
  text-align: center;
}

.card .card-section-1 .content-section-1 h3 span {
  font-weight: 700;
}
.card .card-section-1 .content-section-1 .text-button {
  width: 100%;
  padding: 10px;
  background-color: var(--dark-green);
  color: var(--light-cream);
  font-size: 1.4rem;
  border-radius: 15px;
  margin: 20px 0;
  text-align: center;
}
.card .card-section-1 .content-section-1 h4 {
  font-size: 1.2rem;
  line-height: 1.4;
  color: #000;
  text-shadow: none;
  text-align: center;
}

.card .card-section-1 .image-section-1 {
  text-align: center;
}

.card .card-section-1 .image-section-1 .img-responsive {
  width: 80%;
  border: 4px solid var(--dark-green);
  padding: 1px;
  box-shadow: 0 2px 5px 2px rgba(0, 0, 0,.3);
}
.card .card-section-1 .image-section-1 .img-fluid {
  width: 70%;
  height: 70%;
  border: 4px solid var(--dark-green);
  padding: 1px;
  box-shadow: 0 2px 5px 2px rgba(0, 0, 0,.3);
}

.card .card-section-1 .image-section-1 h5 {
  margin: 10px 0;
  font-size: 1.8rem;
  font-weight: 600;
}

.card .card-section-2 {
  text-align: center;
  color: white;
  background-color: var(--dark-green);
  padding: 20px 0;
  border-radius: 0 0 20px 20px;
}

.card .card-section-2 h2 {
  font-size: 2.5rem;
  font-weight: 700;
  text-shadow: 1px 1px 2px var(--bg-black-50);
}

.fa-angle-down {
  font-size: 4rem;
}

/* Services-card */

#services {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px 40px;
  width: 90%;
  margin: 50px auto;
}

.service-card {
  background-color: white;
  border-radius: 15px;
  border: 1px solid black;
  box-shadow: 0 2px 5px 2px rgba(0, 0, 0, .3);
}

.service-card img {
  width: 100%;
  height: 70%;
  padding: 10px;
  border: 2px solid var(--gray-light);
}

.service-card h2 {
  color: var(--dark-green);
  font-size: 1.5rem;
  text-decoration: underline;
  margin: 10px;
  text-align: center;
}

#whats-going .bg-overlay {
  background-color: var(--dark-cream);
  padding: 20px;
  width: 85%;
  margin: auto;
  color: var(--light-cream);
  text-shadow: 1px 1px 2px var(--bg-black-50);
  text-align: start;
  border-radius: 18px;
}

#whats-going .bg-overlay h2 {
  font-size: 4rem;
  text-decoration: underline;
}

#whats-going .bg-overlay h4, h3 {
  color: white;
  font-size: 2rem;
  text-decoration: none;
  text-align: start;
}

/* 7 days */

.bad-moments {
  margin-top: 40px;
}

.bad-moments p span.bolder {
  font-weight: 700 !important;
  text-decoration: underline !important;
}

.bad-moments h2 {
  color: var(--green);
  font-size: 2rem;
}

.bad-moments p {
  margin: 20px 100px 0 0;
  font-size: 1.4rem;
  width: 80%;
  color: var(--dark-green);
}

.bad-moments p span {
  font-weight: 700;
  color: var(--dark-green);
}

.bad-moments p span:nth-child(2) {
  text-decoration: underline;
}

.bad-moments-button {
  margin-top: 40px;
}

.bad-moments-button a {
  color: white;
  text-decoration: none;
}

/* footer */

#footer {
  background-color: var(--dark-green);
  text-align: center;
  color: white;
  padding: 50px 0;
  font-size: 1.3rem;
}

/* MODAL */

.backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: none;
}


#modal {
  position: absolute;
  top: -180%;
  left: 50%;
  transform: translate(-50%);
  background-color: white;
  margin: 80px 0;
  width: 90%;
  max-width: 950px;
  padding: 20px;
  border: 5px solid black;
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
}

.modal-body {
  width: 80%;
  margin: auto;
  position: relative;
}

.progress-bar {
  margin-top: 100px;
  width: 100%;
  padding: 10px 0;
  background-color: var(--dark-green);
  color: white;
  text-align: center;
  font-size: 1.3rem;
}

.info-block {
  margin-top: 50px;
  width: 100%;
  padding: 10px 0;
  background-color: var(--dark-green);
  color: white;
  text-align: center;
}

.info-block p {
  font-size: 1.5rem;
  font-weight: 700;
}

.info-block h2 {
  font-size: 2rem;
  margin-top: 10px;
  font-weight: 700;
}

.form {
  width: 100%;
}

.form .form-control input {
  width: 100%;
  border: 1px solid var(--gray-light);
  border-radius: 5px;
  margin-top: 20px;
  box-shadow: inset 0 1px 2px rgba(130, 137, 150, 0.2), 0 1px 0 rgba(255, 255, 255, 0.9) !important;
  padding: 20px 10px;
}

.closeModalBtn {
  position: absolute;
  cursor: pointer;
  top: -15px;
  right: -15px;
}

.image img {
  width: 400px !important;
  display: block;
  margin: auto;
}

.image-2 {
  width: 50% !important;
  height: 70% !important;
  margin: 0 auto;
}

.image-2 img {
  width: 100% !important;
  height: 100% !important;
}

/* Whatsapp icon */

.whatsapp-icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  padding: 6px;
  background-color: var(--green-whatsapp);
  position: fixed;
  left: 20px;
  bottom: 20px;
}

.progress {
  background-color: #f5f5f5;
  border-radius: 3px;
  box-shadow: none;
}

.progress-bar {
  background-color: rgb(118, 124, 114);
  box-shadow: none;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress.active .progress-bar, .progress-bar.active {
  animation: progress-bar-stripes 2s linear infinite;
}

.progress-striped .progress-bar, .progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
  background-size: 40px 40px;
}

.wistia_embed, .w-css-reset {
  width: 100% !important;
  height: 100%;
}

/* Media Queries: Responsive Layout */

@media screen and (max-width: 530px) {
  .image img {
    width: 100% !important;
    height: 90% !important;
  }
  .button-sm {
    width: 200px;
  }
  .bad-moments {
    text-align: center;
  }
  .bad-moments p {
    margin: 0;
    width: 100%;
  }
  #whats-going .bg-overlay {
    background-color: var(--dark-cream-solid);
    padding: 0 5px;
  }
  #whats-going .bg-overlay h2 {
    font-size: 2rem;
    padding: 10px 0;
  }

  #whats-going .bg-overlay h3 {
    font-size: 1.5rem;
    line-height: 1.5;
    font-weight: 400;
  }
  #whats-going .bg-overlay h4 {
    font-size: 1.3rem;
    margin-top: 40px;
  }
}

/* For Less the 850px screens */

@media screen and (max-width: 850px) {
  #services {
    grid-template-columns: repeat(2, 1fr);
  }
  .service-card img {
    width: 100%;
    padding: 10px;
    border: 2px solid var(--gray-light);
  }
}

/* For Less then 750px screens */

@media screen and (max-width: 750px) {
  .container {
    width: 100%;
    max-width: 95%;
    margin: 0 auto;
  }
  /* Hero Section */
  #video-container .video-footage {
    width: 100%;
    height: 100%;
  }
  .re-method {
    flex-direction: column;
  }
  .re-method p {
    width: 100%;
    margin: 0 0 20px 0;
  }
  .re-method img {
    width: 500px;
  }
}

/* For Less then 640px screens */

@media screen and (max-width: 640px) {
  #re-method {
    width: 100%;
  }
  #tried-diets {
    width: 90%;
    margin: auto;
  }
  #whats-going .bg-overlay {
    width: 95%;
    padding: 10px;
  }
  .re-method p {
    width: 100%;
    margin: 0 0 20px 0;
  }
  .re-method img {
    width: 100%;
  }
  .small-part {
    height: auto;
    padding: 20px;
  }
  .small-part h2 {
    font-size: 1.5rem;
    margin-top: 0;
  }
  .small-part h3 {
    font-size: 1.4rem;
  }
  #services {
    grid-template-columns: repeat(1, 1fr);
  }
  .image-2 {
    width: 80% !important;
    height: 90% !important;
    margin: 0 auto;
  }

  .image-2 img {
    width: 100% !important;
    height: 100% !important;
  }
}

/* For Less then 770px screens */

@media screen and (max-width: 770px) {
  .card .card-section-1 {
    grid-template-columns: 1fr;
  }
  .card .card-section-1 .image-section-1 .img-responsive {
    order: 0;
  }
  .card .card-section-1 .content-section-1 {
    order: 1;
  }
  .card .card-section-1 .image-section-1 .img-fluid {
    height: 90%;
  }
}

/* For Less then 700px screens */

@media screen and (max-width: 700px) {
  /* Hero text section */
  .hero-text p {
    font-size: 1.1rem;
    font-weight: 300;
    text-shadow: 1px 1px 2px var(--bg-black-50);
  }
  .hero-text h1 {
    font-size: 1.2rem;
    font-weight: 700;
  }
  .hero-text h1:nth-child(3) {
    margin: 22px 0 0 0;
    background-color: var(--green);
    padding: 5px 0;
    text-shadow: 1px 1px 2px var(--bg-black-50);
  }
  /* Button lg */
  .button-lg {
    width: 100%;
    background-color: var(--green);
    padding: 10px 0;
  }
  .button-lg p:nth-child(1) {
    font-size: 1.5rem;
    font-weight: bold;
  }
  .button-lg p:nth-child(2) {
    font-size: 1rem;
  }
  /* Button md */
  .button-md {
    width: 250px;
  }
  .button-md p:nth-child(1) {
    font-size: 1.3rem;
    font-weight: bold;
  }
  .button-md p:nth-child(2) {
    font-size: 1rem;
    font-weight: bold;
    color: var(--light-gray);
  }
  /* Button md */
  .button-md-white {
    width: 250px;
  }
  .button-md-white p:nth-child(1) {
    font-size: 1.3rem;
    font-weight: bold;
  }
  .button-md-white p:nth-child(2) {
    font-size: 1rem;
    font-weight: bold;
  }
  /* Grid */
  .grid {
    display: grid;
    grid-template-columns: 1fr;
    text-align: center;
    margin: 50px 0;
  }
  .grid-reverse {
    display: grid;
    grid-template-columns: 1fr;
    text-align: center;
  }
  .grid-reverse section:nth-child(1) {
    order: 2;
  }
  .grid-reverse section:nth-child(2) {
    order: 1;
  }
  .grid h2, .grid-reverse h2 {
    font-size: 1.8rem;
  }
  .grid p, .grid-reverse p {
    font-size: 1.3rem;
  }
}

/* For Less then 550px screens */

@media screen and (max-width: 550px) {
  .card .card-section-1 .content-section-1 h3 {
    font-size: 1.2rem;
  }
  .card .card-section-1 .content-section-1 .text-button {
    font-size: .9rem;
  }
  .card .card-section-1 .content-section-1 h4 {
    font-size: 1rem;
  }
  .card .card-section-1 .image-section-1 .img-responsive {
    width: 100%;
  }
  .card .card-section-1 .content-section-1 {
    margin: 0 10px;
    padding: 10px;
  }
  .card .card-section-1 .whatsapp-screenshot {
    height: 70%;
  }

  .card .card-section-1 .whatsapp-screenshot img {
    width: 100%;
    height: 80%;
  }
}